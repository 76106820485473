<template>
    <div class="page">
        <van-tabs v-model="active" title-inactive-color="#4A4A4A" @change="handleTabChange" color="#3974C6"
            title-active-color="#3974C6">
            <van-tab title="全部网格"></van-tab>
            <van-tab title="网格客户"></van-tab>
        </van-tabs>
        <div class="">
            <div class="serachBox" v-if="active==0">
                <van-search shape="round" v-model="searchVal" @search="searchDraw" placeholder="请输入客户名称" />
            </div>
            <div class="serachBox2" v-if="active==1">
                <div class="name" @click="openSelectFun()">
                    <span>{{selectedProperty.name==''?'选择网格':selectedProperty.name}}</span>
                    <img src="../../assets/images/grid/jiantou.png" alt="">
                </div>
                <van-search class="serach" shape="round" v-model="searchVal" @search="searchDraw"
                    placeholder="请输入客户名称" />
            </div>
            <div class="hard">
                <div class="infoBox">
                    <div class="con" :class="selectTab==0?'slectCon':''" @click="selectTabFun(0)">
                        <div class="c1">{{gridInfo.total}}</div>
                        <div class="c2">全部</div>
                    </div>
                    <div class="con leftbox" :class="selectTab==1?'slectCon':''" @click="selectTabFun(1)">
                        <div class="c1">{{gridInfo.person_total}}</div>
                        <div class="c2">个人</div>
                    </div>
                    <div class="con leftbox" :class="selectTab==2?'slectCon':''" @click="selectTabFun(2)">
                        <div class="c1">{{gridInfo.merchant_total}}</div>
                        <div class="c2">商户</div>
                    </div>
                </div>
                <div class="mapBox">
                    <div class="baidumap" id="allmap"></div>
                </div>

            </div>
        </div>
        <!-- 网格选项 -->
        <van-popup v-model="propertyShow" round position="bottom" :style="{ height: '50%' }">
            <van-picker show-toolbar :columns="propertyNames" @confirm="propertyOnConfirm" @cancel="propertyOnCancel"
                :default-index="propertyPickerIndex" />
        </van-popup>
        <!-- 用户信息 -->
        <van-popup v-model="pointInfoShow" round position="bottom" :style="{ height: '50%' }">
            <div class="con">
                <div class="t1">
                    <div class="t1-left">客户编号</div>
                    <div class="t1-right">{{pointInfo.serialNumber}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户名称</div>
                    <div class="t1-right">{{pointInfo.name}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户类型</div>
                    <div class="t1-right">{{pointInfo.type}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系人姓名</div>
                    <div class="t1-right">{{pointInfo.contactName}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">联系电话</div>
                    <div class="t1-right">{{pointInfo.contactPhone}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">地址</div>
                    <div class="t1-right">{{pointInfo.address}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">经纬度</div>
                    <div class="t1-right">{{pointInfo.longitude}}，{{pointInfo.latitude}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户状态</div>
                    <div class="t1-right">{{pointInfo.status}}</div>
                </div>
                <div class="t1">
                    <div class="t1-left">客户经理</div>
                    <div class="t1-right">{{pointInfo.accountManagerName}}</div>
                </div>
                <div class="detailBox" @click="printBtn(pointInfo)">更多详情</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
    // import infoTabPage from "./components/infoTabPage.vue";
    // import infoTabNext from "./components/infoTabNext.vue";
    import { getCustom, gridRecord, gridDetail } from "@/api/gridManage";
    export default {
        // components: { infoTabPage, infoTabNext },
        data() {
            return {
                active: 0,
                searchVal: '',
                selectTab: 0,
                map: null,
                gridInfo: {
                    total: 0,
                    person_total: 0,
                    merchant_total: 0
                },
                custmerData: {},
                mapData: [],
                propertyShow: false,
                property: [],//资产选项
                propertyPickerIndex: 0,
                selectedProperty: {
                    id: '',
                    name: '',
                }, // 选择的网格信息
                pointInfoShow: false,
                pointInfo: {},
            }
        },
        computed: {
            propertyNames() {
                return this.property.map(item => item.name);
            },
        },
        created() {
            this.getData()

        },
        mounted() {
            this.$nextTick(function () {
                this.baiduMap()
            })
        },
        methods: {
            selectList() {
                gridRecord().then((res) => {
                    if (res.code == 200) {
                        this.property = res.data
                        //默认选择第一个网格
                        if (res.data.length != 0) {
                            this.selectedProperty = {
                                id: res.data[0].id,
                                name: res.data[0].name,
                            }
                            this.getgridDetail()
                        }
                    }
                })
            },
            handleTabChange() {
                this.selectTab = 0
                this.selectedProperty = {
                    id: '',
                    name: '',
                }
                this.gridInfo = {
                    total: 0,
                    person_total: 0,
                    merchant_total: 0
                }
                this.searchVal = ''
                this.map.clearOverlays()
                if (this.active == 0) {
                    this.getData()
                }
                if (this.active == 1) {
                    this.selectList();
                }
            },
            getData() {
                getCustom({ name: this.searchVal }).then((res) => {
                    if (res.code == 200) {
                        this.gridInfo = res.data
                        this.mapData = res.data.customList;
                        this.setMapPoint()//绘制坐标点
                    }
                })
            },
            searchDraw() {
                if (this.active == 0) {
                    this.getData()
                } else if (this.active == 1) {
                    this.getgridDetail()
                }
            },
            selectTabFun(type) {
                this.selectTab = type
                // 过滤数据
                if (!this.gridInfo.customList || this.gridInfo.customList.length === 0) {
                    return;
                }
                switch (type) {
                    case 0: // 全部
                        this.mapData = this.gridInfo.customList;
                        break;
                    case 1: // 个人
                        this.mapData = this.gridInfo.customList.filter(res => res.type === '个人');
                        break;
                    case 2: // 商户
                        this.mapData = this.gridInfo.customList.filter(res => res.type === '商户');
                        break;
                    default:
                        // 如果type不在上述范围内，可以设定默认行为，例如清空mapData
                        this.mapData = [];
                        break;
                }
                this.setMapPoint();
                if (this.active === 1) {
                    this.setMapGrid(); // 绘制网格
                }
            },
            baiduMap() {
                this.map = new BMap.Map("allmap");
                var point = new BMap.Point(116.404, 39.915) // 创建点坐标
                this.map.centerAndZoom(point, 12) // 初始化地图，设置中心点坐标和地图级别
                this.map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
            },
            //绘制坐标点
            setMapPoint(showType) {
                this.map.clearOverlays()
                let pointData=showType?this.gridInfo.customList:this.mapData;
                if (pointData.length != 0) {
                    this.map.centerAndZoom(new BMap.Point(pointData[0].longitude, pointData[0].latitude), 12); // 初始化
                    pointData.forEach(e => {
                        var point = new BMap.Point(e.longitude, e.latitude);
                        // 自定义图标的设置
                        var personal = require('../../assets/map/personal.png');
                        var merchant = require('../../assets/map/merchant.png');
                        var point = new BMap.Point(e.longitude, e.latitude);
                        // // 创建一个 Icon 对象，使用本地图片作为图标
                        var personalIcon = new BMap.Icon(personal, new BMap.Size(25, 34), {
                            imageSize: new BMap.Size(25, 34), // 指定图片的大小
                        });
                        var merchantIcon = new BMap.Icon(merchant, new BMap.Size(25, 34), {
                            imageSize: new BMap.Size(25, 34), // 指定图片的大小
                        });
                        var marker = new BMap.Marker(point, {
                            icon: e.type == '商户' ? merchantIcon : personalIcon
                        });
                        this.map.addOverlay(marker);
                        // 创建信息窗口
                        var infoWindowContent = document.createElement('div');
                        infoWindowContent.innerHTML = `
                        <div style="font-size:12px">客户名称: ${e.name}</div>
                        <div style="font-size:12px">客户类型 : ${e.type}</div>
                        <div style="font-size:12px">联系人姓名: ${e.contactName}</div>
                        <div style="font-size:12px">联系电话 : ${e.contactPhone}</div>
                        <div style="font-size:12px">地址: ${e.address}</div>
                        <div style="font-size:12px">经纬度: ${e.longitude}${e.latitude}</div>
                        <div style="font-size:12px">客户状态 : ${e.status}</div>
                        <div style="font-size:12px">客户经理: ${e.accountManagerName}</div>
                        <div style="font-size:12px;color:#3975C6" id="detailButton" sid="'+ e.id +'">更多详情</div>
                        `;
                        var infoWindow = new BMap.InfoWindow(infoWindowContent, { width: 200 });
                        // 添加点击事件监听器
                        marker.addEventListener('click', () => {
                            // this.map.openInfoWindow(infoWindow, point);
                            that.pointOpenInfo(e)
                        });
                        var that = this;
                        // 获取按钮并添加点击事件监听器
                        var detailButton = infoWindowContent.querySelector('#detailButton');
                        detailButton.addEventListener('click', function () {
                            that.printBtn(e);
                        });
                    });
                }
            },
            openSelectFun() {
                this.propertyShow = true
            },
            //网格选择
            propertyOnConfirm(name) {
                const selectedProperty = this.property.find(item => item.name === name);
                if (selectedProperty) {
                    this.selectedProperty = {
                        id: selectedProperty.id,
                        name: selectedProperty.name,
                    }
                    this.getgridDetail()
                }
                this.selectTab = 0;
                this.propertyShow = false
            },
            propertyOnCancel() {
                this.propertyShow = false
            },
            getgridDetail() {
                this.map.clearOverlays()
                let params = {
                    id: this.selectedProperty.id,
                    name: this.searchVal
                }
                gridDetail(params).then(async (res) => {
                    if (res.code == 200) {
                        this.custmerData = res.data
                        this.mapData = res.data.customList;
                        //首先循环客户
                        //循环  点线面数据
                        //判断点是否等于点
                        // 判断客户是否存在面里面
                        //判断客户是否在行政面里面
                        //如果存在则push进数组
                        let newData = await this.resultDataA()
                        console.log("newData", newData)
                        this.gridInfo.customList = newData
                        this.gridInfo.total = newData.length;
                        this.gridInfo.person_total = newData.filter(res => res.type === '个人').length;
                        this.gridInfo.merchant_total = newData.filter(res => res.type === '商户').length;
                        this.setMapPoint('part')//绘制点
                        this.setMapGrid()//绘制网格
                    }
                })
            },
            //处理数据
            // resultData() {
            //     if (this.mapData.length == 0) return;
            //     let newArr = []
            //     this.mapData.forEach((i, index) => {
            //         this.gridInfo.grid.range.forEach((row, rindex) => {
            //             if (row.type == 1 && row.relationLatitudeLongitude.length != 0) {
            //                 row.relationLatitudeLongitude.forEach((data, dIndex) => {
            //                     if (i.longitude == data.lng && i.latitude == data.lat) {//点等于点
            //                         newArr.push(i)
            //                     }
            //                 })
            //             }
            //             if (row.type == 2) {
            //                 var apolygon_points = new Array();
            //                 row.relationLatitudeLongitude.forEach((t, tindex) => {
            //                     apolygon_points.push(new BMap.Point(t.lng, t.lat))
            //                 })
            //                 var polygon = new BMap.Polygon(apolygon_points, { strokeColor: "blue", strokeWeight: 2, strokeOpacity: 0.5 });
            //                 this.map.addOverlay(polygon);
            //                 var mypoint = new BMap.Point(i.longitude, i.latitude);//定义一个坐标对象
            //                 if (BMapLib.GeoUtils.isPointInPolygon(mypoint, polygon)) {
            //                     newArr.push(i)
            //                 }
            //             }
            //             if (row.type == 3) {
            //                 let map = this.map
            //                 var bdary = new BMap.Boundary();
            //                 bdary.get(row.administrativeAspectAddress, function (rs) {  //获取行政区域
            //                     var count = rs.boundaries.length; //行政区域的点有多少个
            //                     for (var j = 0; j < count; j++) {
            //                         var ply = new BMap.Polygon(rs.boundaries[j], { strokeWeight: 2, strokeColor: "#ff0000" }); //建立多边形覆盖物
            //                         var mypoint = new BMap.Point(i.longitude, i.latitude);//定义一个坐标对象
            //                         if (BMapLib.GeoUtils.isPointInPolygon(mypoint, ply)) {
            //                             newArr.push(i)
            //                         }
            //                     }
            //                 });
            //             }
            //             console.log('newArr', newArr)

            //         })
            //     })
            // },
            async resultDataA() {
                if (this.mapData.length === 0) return;

                let newArr = [];
                const processMapData = (i, index) => {
                    let isP = false;
                    return this.custmerData.grid.range.reduce((promiseChain, row, rindex) => {
                        return promiseChain.then(() => {
                            if (isP) return; // 如果已经找到匹配项，不再继续处理
                            if (row.type === 1 && row.relationLatitudeLongitude.length !== 0) {
                                return row.relationLatitudeLongitude.some((data, dIndex) => {
                                    if (i.longitude === data.lng && i.latitude === data.lat) {
                                        isP = true;
                                        newArr.push(i);
                                        return true; // 找到匹配项，中断循环
                                    }
                                    return false;
                                });
                            }

                            if (row.type === 2) {
                                const apolygon_points = row.relationLatitudeLongitude.map(t => new BMap.Point(t.lng, t.lat));
                                const polygon = new BMap.Polygon(apolygon_points, { strokeColor: "blue", strokeWeight: 2, strokeOpacity: 0.5 });
                                this.map.addOverlay(polygon);
                                const mypoint = new BMap.Point(i.longitude, i.latitude);
                                if (BMapLib.GeoUtils.isPointInPolygon(mypoint, polygon)) {
                                    isP = true;
                                    newArr.push(i);
                                }
                            }

                            if (row.type === 3) {
                                return new Promise(resolve => {
                                    let map = this.map;
                                    var bdary = new BMap.Boundary();
                                    bdary.get(row.administrativeAspectAddress, (rs) => {
                                        var count = rs.boundaries.length;
                                        for (var j = 0; j < count; j++) {
                                            var ply = new BMap.Polygon(rs.boundaries[j], { strokeWeight: 2, strokeColor: "#ff0000" });
                                            var mypoint = new BMap.Point(i.longitude, i.latitude);
                                            if (BMapLib.GeoUtils.isPointInPolygon(mypoint, ply)) {
                                                newArr.push(i);
                                                isP = true;
                                            }
                                        }
                                        resolve();
                                    });
                                });
                            }
                        });
                    }, Promise.resolve());
                };

                const promises = this.mapData.map(i => processMapData(i, this.mapData.indexOf(i)));
                await Promise.all(promises);

                return newArr;
            },
            //绘制网格
            setMapGrid() {
                if (this.custmerData.grid.length != 0) {
                    this.custmerData.grid.range.forEach((row) => {
                        if (row.type == 1) {
                            if (row.relationLatitudeLongitude.length != 0) {
                                // this.map.clearOverlays()
                                this.map.centerAndZoom(new BMap.Point(row.relationLatitudeLongitude[0].lng, row.relationLatitudeLongitude[0].lat), 12); // 初始化
                                row.relationLatitudeLongitude.forEach(e => {
                                    var marker = new BMap.Marker(new BMap.Point(e.lng, e.lat));
                                    this.map.addOverlay(marker);
                                });
                            }
                        }
                        if (row.type == 2) {
                            if (row.relationLatitudeLongitude.length != 0) {
                                this.map.centerAndZoom(new BMap.Point(row.relationLatitudeLongitude[0].lng, row.relationLatitudeLongitude[0].lat), 12); // 初始化
                                var polygon = new BMap.Polygon(row.relationLatitudeLongitude, { strokeColor: "blue", strokeWeight: 2, strokeOpacity: 0.5 });
                                this.map.addOverlay(polygon);
                            }
                        }
                        if (row.type == 3) {
                            // this.map.clearOverlays()
                            this.dist = null;
                            this.getBoundary(row.administrativeAspectAddress)
                        }
                    })
                }

            },
            getBoundary(str) {
                let map = this.map
                var bdary = new BMap.Boundary();
                bdary.get(str, function (rs) {  //获取行政区域
                    // map.clearOverlays();        //清除地图覆盖物
                    var count = rs.boundaries.length; //行政区域的点有多少个
                    if (count === 0) {
                        alert('未能获取当前输入行政区域');
                        return;
                    }
                    var pointArray = [];
                    for (var i = 0; i < count; i++) {
                        var ply = new BMap.Polygon(rs.boundaries[i], { strokeWeight: 2, strokeColor: "#ff0000" }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        pointArray = pointArray.concat(ply.getPath());
                    }
                    map.setViewport(pointArray);    //调整视野
                });
            },
            pointOpenInfo(e) {
                this.pointInfo = e
                this.pointInfoShow = true
            },
            //坐标点查看详情
            printBtn(e) {
                this.$router.push({
                    path: '/gridManage/infoDetail',
                    query: {
                        id: e.id
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/.van-overlay {
        background-color: rgba(0, 0, 0, 0.1);
    }

    /deep/ .van-tabs__nav--line {
        line-height: 48px;
        padding-bottom: 0;
    }

    /deep/ .van-tabs__line {
        bottom: 0;
    }

    .serachBox2 {
        padding: 5px 17px;
        border-top: 1px solid #E8E8E8;
        background: #FFFFFF;
        display: flex;
        flex-direction: row;

        .name {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 80px;

            white-space: nowrap;
            /* 防止文字换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 在文本被裁剪的地方显示省略标记(...) */

            span {
                font-weight: 400;
                font-size: 14px;
                color: #999999;
            }

            img {
                width: 7px;
                height: 5px;
                margin-left: 5px;
            }
        }

        .serach {
            width: 80%;
        }
    }

    .page {
        background: #F2F3F7;
        width: 100%;
        min-height: 100vh;
    }

    .serachBox {
        padding: 5px 0px;
        border-top: 1px solid #E8E8E8;
        background: #FFFFFF;
    }

    .hard {
        margin-top: 10px;
        background: #FFFFFF;
        width: 100%;
        height: 100%;
        padding: 20px 10px;

        .infoBox {
            display: flex;
            flex-direction: row;

            .con {
                padding: 10px 0;
                width: calc(100% - 33%);
                text-align: center;
                background: #F2F3F7;
                border-radius: 2px;

                .c1 {
                    font-weight: bold;
                    font-size: 22px;
                    color: #000000;

                }

                .c2 {
                    font-weight: 400;
                    font-size: 11px;
                    color: #9B9B9B;
                }
            }

            .slectCon {
                background: #3975C6;

                .c1 {
                    color: #FFFFFF;
                }

                .c2 {
                    color: #FFFFFF;
                }
            }

            .leftbox {
                margin-left: 6px;
            }
        }

        .mapBox {
            margin-top: 20px;

            .baidumap {
                width: 100%;
                height: 600px;
            }
        }
    }

    .mapText {
        font-size: 12px;
    }

    .con {
        padding: 0px 16px;
        padding-bottom: 10px;
        padding-top: 1px;
        background: #FFFFFF;
        border-radius: 5px;

        .t1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 0;

            .t1-left {
                font-weight: 400;
                font-size: 15px;
                color: #9B9B9B;
            }

            .t1-right {
                font-weight: 400;
                font-size: 15px;
                color: #515151;
                max-width: 70%;

            }

            .bor {
                width: 100%;
                border-bottom: 1px solid #E8E8E8;
            }
        }

        .detailBox {
            margin: 10px 0px 25px 10px;
            text-align: center;
            font-weight: 400;
            font-size: 15px;
            color: #3975C6;
        }

    }
</style>