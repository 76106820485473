<template>
    <div>
        <van-dialog v-model="dialogShow" :showConfirmButton="false" :showCancelButton="false" class="dialogBox">
            <van-loading size="32px" type="spinner"></van-loading>
        </van-dialog>
        <div class="filter">
            <div class="filterLeft">共 {{ length }} 个结果</div>
            <div class="filterRight" @click="$refs.fit.$show()">
                <img src="../../../assets/filter.png" class="icon" alt="" /><span>筛选</span>
            </div>
        </div>
        <div class="main">
            <div v-if="qunfaListData.length > 0">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div style="width: 94%; margin: 0 auto">
                        <div class="mainTop"></div>
                        <div class="mainList" v-for="(res, ind) in qunfaListData" :key="ind" @click="goDetail(res)">
                            <div class="content" style="width: 94%; margin: 0 auto">
                                <div class="listTop">
                                    <div class="name">
                                        {{ res.merchantName }}
                                    </div>
                                    <div class="status" :style="setColor(res.reviewStatus)">
                                        {{res.reviewStatus}}
                                    </div>
                                </div>
                                <div class="address" @click.stop="handleClick(res)">
                                    <span>{{res.address}}</span>
                                    <img src="../../../assets/images/ins/loca.png" alt="">
                                </div>
                                <div class="address m10" @click.stop="playPhone(res.contactPhone)">
                                    <span>{{maskPhone(res.contactPhone)}}</span>
                                    <img style="width: 10px;height: 12px;" src="../../../assets/credit/call.png" alt="">
                                </div>
                                <div class="m16">
                                    <div class="m16-left">{{res.merchantType}}</div>
                                    <div class="m16-right" v-if="res.reviewStatus=='未巡检'"
                                        @click.stop="goPage('/Inspection/insSubmit',res)">巡检</div>
                                    <div class="m16-right" v-else @click.stop="goDetail(res)">查看</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
            <div v-else>
                <van-empty description="暂无数据" />
            </div>
        </div>
        <Moment ref="fit" @filterData="getFliterData" />
        <baidu-map style="display:none;" ak="uSDQM0SXJgOKGoiDmMxh5a1BR0zhoKVJ" @ready="handler"></baidu-map>
    </div>
</template>

<script>
    import Moment from "./taskFilter.vue";
    import { qunfaList, roomList, qunfaCircleList } from "@/api/qunfa";
    import { inspectionIndex, setLocation } from "@/api/Inspection";
    import { openUserLocation } from "@/utils/wxCodeAuth";
    import axios from 'axios'
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    export default {
        components: { Moment, BaiduMap },
        data() {
            return {
                qunfaListData: [],
                time: false,
                tabs: ["巡检任务", "商户管理"],
                loading: false,
                finished: false,
                page: 1,
                searchVal: "",
                showMy: false,
                active: 0,
                showAction: false,
                length: 0,
                type: 0,
                filterParam: {},
                merchantName: '',
                taskId: '',
                dialogShow: false,
                debounceTimer: null, // 用于存储防抖计时器
            }
        },
        watch: {},
        created() {
            //不是信息跳转进来，或者跳转进来是巡检任务的页面，手动切换到任务
            if (!this.$route.query.taskId || this.$route.query.type == 2) {
                this.getList();
            }
        },
        beforeRouteLeave(to, from, next) {
            console.log("to from", to, from);
            if (to.path === "/Inspection/insSubmit" || to.path === "/Inspection/taskDetail") {
               from.meta.keepAlive = true;
            }
            next();
        },
        methods: {
            async handler({ BMap, map }) {
                //创建地址解析器实例
                this.gc = new BMap.Geocoder();
            },
            // 搜索事件处理函数
            handleMerchantSearch(res) {
                this.merchantName = res
                this.qunfaListData = [];
                this.getList();
            },
            //从消息进入搜索事件
            templateSearch(id) {
                this.taskId = id
                this.qunfaListData = [];
                this.getList();
            },
            onLoad() {
                this.page++;
                this.getList();
            },
            getList() {
                inspectionIndex({
                    ...this.filterParam,
                    page: this.page,
                    perPage: 10,
                    merchantName: this.merchantName,
                    taskId: this.taskId
                }).then((res) => {
                    this.qunfaListData.push(...res.data.list);
                    this.length = res.data.page.total;
                    // 加载状态结束
                    this.loading = false;
                    //搜索无数据时提示
                    if (this.merchantName != '' && res.data.page.total == 0) {
                        this.$message.warning("暂无数据！");
                    }
                    // 数据全部加载完成
                    if (this.qunfaListData.length >= res.data.page.total) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }
                });

            },
            getFliterData(e) {
                e.merchantType.includes(0) ? e.merchantType = [] : '';//是否包含全部，包含全部不传条件
                e.reviewStatus.includes(0) ? e.reviewStatus = [] : '';//是否包含全部，包含全部不传条件
                this.filterParam = e;
                this.qunfaListData = [];
                this.finished = false;
                this.page = 1;
                this.getList();
            },
            setColor(type) {
                const colorMap = {
                    '未巡检': 'background: #FFDAD8; color: #D2261D',
                    '待审核': 'background: #EBEFFB; color: #3974C6', // 假设的颜色，根据实际情况调整
                    '审核通过': 'background: #D7F3D4; color: #39A52E', // 假设的颜色，根据实际情况调整
                    '审核驳回': 'background: #F2F3F7; color: #9B9B9B', // 假设的颜色，根据实际情况调整
                };
                return colorMap[type] || ''; // 返回对应的状态颜色，如果状态不存在则返回空字符串
            },
            goPage(url, row) {
                this.$router.push({
                    path: url,
                    query: {
                        id: row.id,
                    },
                });
            },
            //前往详情
            goDetail(row) {
                this.$router.push({
                    path: '/Inspection/taskDetail',
                    query: {
                        id: row.id,
                        merchantId: row.merchantId
                    },
                });
            },
            // 包装后的点击事件处理函数
            handleClick(res) {
                this.immediateDebounce(() => this.showMap(res), 1000); // 3000毫秒内多次点击只执行一次
            },
            // 立即执行的防抖函数
            immediateDebounce(func, delay) {
                if (!this.debounceTimer) {
                    // 如果没有设置过计时器，则立即执行func，并设置计时器
                    func();
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null; // 3秒后重置计时器
                    }, delay);
                } else {
                    // 如果计时器已设置（即在延迟时间内再次点击），则清除现有计时器并重新开始计时
                    clearTimeout(this.debounceTimer);
                    this.debounceTimer = setTimeout(() => {
                        this.debounceTimer = null;
                    }, delay);
                }
            },
            // 手动实现的防抖函数
            debounce(func, delay) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => func(), delay);
            },
            //地图显示
            async showMap(row) {
                this.dialogShow = true
                if (row.latitude == '') {//需要调用接口获取经纬度
                    try{
                    var geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
                    geocoder.getLocation({ address: row.address })
                        .then((point) => {
                        if (point) {
                            let params = {
                                id: row.merchantId,
                                longitude: point.result.location.lng,
                                latitude: point.result.location.lat,
                            }
                            this.setlngInfo(params)
                            let openParams = {
                                address: row.address,
                                lng: point.result.location.lng,
                                lat: point.result.location.lat,
                            }
                            setTimeout(() => {
                                this.dialogShow = false
                                openUserLocation(openParams)
                            }, 1000)
                        } else {
                            this.$toast("地址解析失败~");
                            this.dialogShow = false
                        }
                    }).catch((err) => {
                            this.dialogShow = false
                            this.$toast("地址解析失败~");
                        });
                    }catch (e) {
                        this.dialogShow = false
                        this.$toast("地址解析失败~");
                    }
                } else {
                    let openParams = {
                        address: row.address,
                        lng: row.longitude,
                        lat: row.latitude,
                    }
                    setTimeout(() => {
                        this.dialogShow = false
                        openUserLocation(openParams)
                    }, 1000)
                }
            },
            /**
            * @description 百度转腾讯
            * @description BD-09 转  GCJ-02
            * @param{*}list [lng,lat]
            */
            bd09ToGcj02(bdLng, bdLat) {
                const x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                let x = bdLng - 0.0065;
                let y = bdLat - 0.006;
                let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                let ggLng = z * Math.cos(theta);
                let ggLat = z * Math.sin(theta);

                return { lng: ggLng, lat: ggLat };
            },
            //设置经纬度
            setlngInfo(params) {
                setLocation(params).then(() => {
                    this.qunfaListData = [];
                    this.getList();
                })
            },
            playPhone(phoneNum) {
                window.location.href = `tel:${phoneNum}`;
            },
            maskPhone(contactPhone) {
            if (contactPhone) {
                // 假设我们想要替换中间4位数字为星号
                const firstPart = contactPhone.slice(0, 3);
                const lastPart = contactPhone.slice(-4);
                const maskedMiddle = '*'.repeat(4); // 4个星号
                return `${firstPart}${maskedMiddle}${lastPart}`;
            }
            }
        }
    }
</script>

<style lang="less" scoped>
    .filter {
        width: 100%;
        height: 49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border-top: 1px solid #e8e8e8;

        .filterLeft {
            margin-left: 4%;
            font-size: 12px;
            color: #a5a5a5;
        }

        .filterRight {
            margin-right: 4%;
            font-size: 12px;
            color: #515151;

            .icon {
                width: 11px;
                height: 11px;
                margin-right: 6px;
                margin-top: -2px;
            }
        }
    }

    .main {
        height: 100vh;
        background: #f2f3f7;

        .mainTop {
            height: 17px;
            font-size: 12px;
            color: #9b9b9b;
        }

        .mainList {
            width: 100%;
            /* height: 129px; */
            background: #ffffff;
            border-radius: 5px;
            margin-bottom: 10px;
        }

        .listTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* height: 56px; */
            /* border-bottom: 1px solid #f1f1f1; */

            .name {
                /* height: 56px;
                line-height: 56px; */
                width: 80%;
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                /* white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden; */
                padding: 10px 0;
            }

            .status {
                font-size: 12px;
                font-weight: 500;
                color: #3975c6;
                height: 21px;
                line-height: 21px;
                padding: 0px 4px;
                border-radius: 3px;
            }

            .completed {
                font-size: 12px;
                font-weight: 500;
                color: #a5a5a5;
            }
        }

        .address {
            line-height: 1.5;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;

            span {
                font-weight: 400;
                font-size: 12px;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            img {
                margin-left: 5px;
                width: 11px;
                height: 11px;
            }
        }
        .m10{
            margin-top: 10px;
        }

        .m16 {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            border-top: 1px solid #f1f1f1;
            margin-top: 15px;
            padding: 15px 0px;

            .m16-left {
                font-weight: 400;
                font-size: 12px;
                color: #9B9B9B;
            }

            .m16-right {
                width: 50px;
                height: 24px;
                background: #3974C6;
                border-radius: 12px;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
            }
        }
    }

    .dialogBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    }
</style>