-<template>
    <div class="index">
        <div class="top">
            <div class="topContent">
                <div class="topLeft">
                    <span class="team">我的</span>
                    <img class="change" src="../../assets/qiehuan.png" alt="" />
                </div>
                <div class="topRight" v-show="active!=2">
                    <van-search shape="round" v-model="searchVal" @search="handleSearch" @clear="handleSearch" clearable
                        placeholder="搜索" />
                </div>
            </div>
        </div>
        <!-- <taskPage ref="task"  ></taskPage> -->
        <div class="tab">
            <van-tabs v-model="active" title-inactive-color="#4A4A4A" color="#3974C6" title-active-color="#3974C6"
                @change="handleTabChange">
                <van-tab :title="item" v-for="(item, index) in tabs" :key="index">
                    <keep-alive>
                    <taskPage ref="task" v-show="active==0"></taskPage>
                    <merchant ref="merchant" v-show="active==1"></merchant>
                    <statistics ref="statistics" v-if="active==2"></statistics>
                </keep-alive>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
<script>
    import { qunfaList, roomList, qunfaCircleList } from "@/api/qunfa";
    import taskPage from "./components/task.vue";
    import merchant from "./components/merchant.vue";
    import statistics from "./components/statistics.vue"
    export default {
        components: { taskPage, merchant,statistics },
        data() {
            return {
                time: false,
                tabs: ["巡检任务", "商户管理","数据统计"],
                loading: false,
                finished: false,
                page: 1,
                searchVal: "",
                showMy: false,
                active: 0,
                showAction: false,
                length: 0,
                qunfaListData: [],
                type: 0,
                filterParam: {},
            };
        },
        created() {
            if(this.$route.query.type){//表示从模版消息跳转进
                this.active=this.$route.query.type-1
                if (this.$route.query.type == 1) {
                    this.$nextTick(() => {
                        this.$refs.task[0].templateSearch(this.$route.query.taskId);
                    });
                }
            }
        },
        methods: {
            handleTabChange() {
                this.qunfaListData = [];
                this.finished = false;
                this.page = 1;
                this.searchVal = ''
            },
            //搜索功能
            handleSearch() {
                if (this.active == 0) {
                    this.$nextTick(() => {
                        this.$refs.task[0].handleMerchantSearch(this.searchVal);
                    });
                } else if (this.active == 1) {
                    this.$nextTick(() => {
                    this.$refs.merchant[1].handleMerchantSearch(this.searchVal);
                    });
                }
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .van-search {
        width: 100%;
        padding: 16px 0 14px 0;
        background-color: #3975c6;
        margin-right: 16px;
    }

    /deep/ .van-search__content {
        background-color: #fff;
        opacity: 0.51;
        color: #fff;
    }

    /deep/ .van-tabs__nav--line {
        line-height: 48px;
        padding-bottom: 0;
    }

    /deep/ .van-tabs__line {
        bottom: 0;
    }

    .index {
        height: 100%;
    }

    .top {
        width: 100%;
        height: 64px;
        background: #3975c6;

        .topContent {
            width: 92%;
            height: 64px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .topLeft {
            height: 64px;
            line-height: 64px;
            display: flex;
            align-items: center;

            .team {
                font-size: 15px;
                font-weight: bold;
                color: #ffffff;
                margin-right: 6px;
            }

            .change {
                width: 14px;
                height: 11px;
            }
        }

        .topRight {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
        }

        .add {
            width: 25px;
            height: 25px;
        }
    }

    .tab {
        height: 48px;
        /* line-height: 48px; */
    }
</style>